import { useLocation } from 'react-router-dom';

import './App.css';
import CheckEligibility from './components/CheckEligibility';
import Nav from './components/UI/Nav';
import Footer from './components/UI/Footer';
function App() {
  const location = useLocation();
  const agreedFirstName = location?.state?.firstName || '';
  const agreedlastName = location?.state?.lastName || '';
  const agreedEmail = location?.state?.email || '';

  return (
    <>
      <Nav
        firstName={agreedFirstName}
        lastName={agreedlastName}
        email={agreedEmail}
      />
      <CheckEligibility />
      <Footer />
    </>
  );
}

export default App;
