import * as yup from 'yup';
const phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const date = new Date();
const currentUTCTime = date.toISOString();
const validationSchema = yup.object({
  // Received: yup
  //   .date('Enter Date of Request.')
  //   .required('Date of Request is required.'),
  StartOfCare: yup.date('Enter SOC date.').required('SOC Date is required.'),
  sex: yup.string('Select Gender.').required('Gender is required.'),
  City: yup.string('Enter City.').required('City is required.'),
  State: yup.string('Enter state.').required('State is required.'),
  PostalCode: yup
    .string('Enter postal code.')
    .required('Postal code is required.'),
  County: yup.string('Enter County.').required('County is required.'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required.'),
  Extension: yup.number('Enter valid extension.'),
  LanguagePreferenceValue: yup
    .string('Enter valid member preferred language.')
    .required('Member preferred language is required.'),
  healthPlanName: yup
    .string('Enter health plan name.')
    .required('Health plan is required.'),
  InsurancePolicyId: yup
    .string('Enter the Insurance Policy ID.')
    .required('Insurance Policy ID is required'),
  //MedicareHICN: yup.string().matches(mbiRegex, MBIErrorMessage),
  emergencyContactName: yup.string('Enter emergency contact name.'),
  emerContactRelationship: yup.string('Enter emergency contact relationship.'),
  emerContactNumber: yup
    .string('Enter emergency contact phone number.')
    .matches(phoneRegExp, 'Emergency contact phone number is not valid.'),
  opn: yup
    .string('Enter Ordering Prescriber Name.')
    .required('Ordering Prescriber name is required.'),
  opCity: yup
    .string('Enter Ordering Prescriber City.')
    .required('Ordering Prescriber city is required.'),
  opState: yup
    .string('Enter Ordering Prescriber State.')
    .required('Ordering Prescriber state is required.'),
  opCounty: yup
    .string('Enter Ordering Prescriber County.')
    .required('Ordering Prescriber county is required.'),
  opPostalCode: yup
    .number('Enter Ordering Prescriber Postal Code.')
    .required('Ordering Prescriber postal code is required.'),
  opPhoneNumber: yup
    .string('Enter Ordering Prescriber Phone number.')
    .matches(phoneRegExp, 'Ordering Presciber phone number is not valid')
    .required('Ordering Prescriber phone number is required.'),
  opFax: yup
    .string('Enter Ordering Prescriber Fax.')
    .matches(phoneRegExp, 'Ordering Prescriber fax is not valid.')
    .required('Ordering Prescriber fax is required.'),
  pcpName: yup
    .string("Enter patient's PCP name.")
    .required("Patient's PCP name is required."),
  dateOfDischarge: yup
    .date('Enter the Date of Discharge.')
    .required('Date of Discharge is required.'),
  Diagnosis: yup
    .string('Enter the Primary Diagnosis.')
    .required('Primary Diagnosis is required.'),
  DiagnosisCode: yup
    .string('Enter primary diagnosis code.')
    .required('Primary Diagnosis Code is required.'),
  // PriorMedicalHistory: yup
  //   .string('Enter past medical history.')
  //   .required('Medical History is required.'),
  ReferralSourceType: yup
    .string('Enter the referral source.')
    .required('Referral source is required.'),
  FacilityName: yup
    .string("Enter the referral source's name.")
    .required("Referral source's name is required."),
  Name: yup
    .string("Enter the referral source's contact.")
    .required("Referral source's contact is required."),
  Phone: yup
    .string("Enter the referral source's Phone number.")
    .matches(phoneRegExp, 'Referral source phone number is not valid.')
    .required("Referral source's phone number is required."),
  Fax: yup
    .string("Enter the referral source's Fax.")
    .matches(phoneRegExp, 'Referral source fax is not valid.')
    .required('Referral source fax is required.'),
  ProviderEmail: yup
    .string("Enter the agency's email.")
    .email('Enter a valid agency email.'),
  StaffingReferralAgencyName: yup.string('Enter the name of the agency.'),
  // agencyPhone: yup
  //   .string("Enter the Agency's Phone Number.")
  //   .matches(phoneRegExp, 'Agency phone number is not valid'),
  ProviderFax: yup
    .string("Enter the Agency's Fax.")
    .matches(phoneRegExp, 'Agency fax is not valid'),
  ApprovedAuthPreference: yup.string('Select where to Send Approved Auth.'),
  ClinicalGroup: yup
    .string('Select the clinical grouping.')
    .required('Clinical Grouping is required.'),
  PriorityCareTypes: yup
    .string('Select Care Type.')
    .required('Care Type is required.'),
  GroupName: yup.string().max(25, 'Email must be no more than 25 characters.'),
  Urgency: yup.string().required('Urgency is required.'),
  UrgencyReason: yup
    .string()
    .max(250, 'Urgency reason must not be more than 250 characters.'),
  SecondaryDiagnosisCode: yup.string('Enter the Secondary Diagnosis Code.'),
  orderingPrescriberTIN: yup.number('Enter the Ordering Prescriber TIN.'),
  orderingPrescriberSpecialty: yup.string(
    'Enter the Ordering Prescriber Speciality.'
  ),
  previousF2FDate: yup.date('Enter previous encounter date.'),
  previousF2FPhysician: yup.string(
    'Enter the name of the provider who confirmed Face to Face visit.'
  ),
});

const initialValues = {
  //Received: currentUTCTime, // date of request
  StartOfCare: '', // socDate
  sex: '',
  //Street: '',
  City: '',
  State: '',
  PostalCode: '',
  County: '',
  phoneNumber: '',
  Extension: '',
  healthPlanName: '',
  InsurancePolicyId: '',
  //MedicareHICN: '',
  emerContactNumber: '',
  emerContactRelationship: '',
  emergencyContactName: '',
  LanguagePreferenceValue: '',
  opn: '',
  // opNPI: '5345353534534',
  // opStreet: '2323 main street',
  opCity: '',
  opState: '',
  opCounty: '',
  opPostalCode: '',
  opPhoneNumber: '',
  opFax: '',
  pcpName: '',
  dateOfDischarge: '',
  Diagnosis: '',
  DiagnosisCode: '',
  PriorMedicalHistory: '',
  ReferralSourceType: '', // referral source
  FacilityName: '', // referral source name
  //NPiID: '3535345345345', // referral source NPI
  Name: '', // referral source name
  Phone: '', // referral source phone
  Fax: '', // referral source fax
  AgencyId: '',
  ProviderEmail: '',
  StaffingReferralAgencyName: '', //agency contact name
  // agencyPhone: '',
  ProviderFax: '',
  ApprovedAuthPreference: '',
  ClinicalGroup: '',
  PriorityCareTypes: '',
  Disciplines: [],
  attachments: [],
  GroupName: '',
  Urgency: '',
  UrgencyReason: '',
  SecondaryDiagnosisCode: '',
  orderingPrescriberTIN: '',
  orderingPrescriberSpecialty: '',
  previousF2FDate: '',
  previousF2FPhysician: '',
};

const newinitialValues = {
  Received: '1990-12-19', // date of request
  StartOfCare: '1990-12-19', // socDate
  sex: 'Male',
  Street: '645 N Westville',
  City: 'Phoenix',
  State: 'AZ',
  PostalCode: '84523',
  County: 'Maricopa',
  phoneNumber: '8455658989',
  healthPlanName: 'Cetna',
  InsurancePolicyId: '6454565123',
  MedicareHICN: '56151361',
  emerContactNumber: '4165451623',
  emerContactRelationship: 'Son',
  emergencyContactName: 'Son tho',
  LanguagePreferenceValue: 'English',
  opn: 'Dr. Fauci',
  opNPI: '4685151521',
  opStreet: '234 N Main street',
  opCity: 'Columbus',
  opState: 'GA',
  opCounty: 'Fulton',
  opPostalCode: '89445',
  opPhoneNumber: '8458965444',
  opFax: '8795456233',
  pcpName: 'Yoshi',
  dateOfDischarge: '1990-12-19',
  Diagnosis: 'Cold',
  DiagnosisCode: 'PM3',
  PriorMedicalHistory: 'Covid, Flu',
  ReferralSourceType: 'Hospital', // referral source
  FacilityName: 'St. Judes', // referral source name
  NPiID: '564264584', // referral source NPI
  Name: 'Dr. Philly', // referral source name
  Phone: '8465235445', // referral source phone
  Fax: '8465223555', // referral source fax
  AgencyName: 'Hospital',
  ProviderEmail: 'kdfdj@gmail.com',
  StaffingReferralAgencyName: 'Hospital', //agency contact name
  agencyPhone: '8455456565',
  ClinicalGroup: 'General/Other',
  PriorityCareTypes: 'LVAD',
  Disciplines: [],
  AcceptingAgency: 'Hospital',
  ProviderFax: '8924379283',
  ApprovedAuthPreference: 'Email',
  AgencyId: '',
  attachments: [],
};

const disciplineOptions = [
  { label: 'Skilled Nursing', value: 'SkilledNursing' },
  { label: 'Physical Therapy', value: 'PhysicalTherapy' },
  { label: 'Occupational Therapy', value: 'OccupationalTherapy' },
  { label: 'Speech Therapy', value: 'SpeechTherapy' },
  { label: 'Home Health Aide', value: 'HomeHealthAide' },
  { label: 'Medical Social Worker', value: 'SocialWork' },
];

const clinicalGroupingList = [
  'General/Other: Med Reconciliation/Teach, Safety Eval, Eval/Treat',
  'Wound Vac',
  'Daily Wound Care',
  'General Wound (Wound Care - Eval / Treat or Wound Assessment)',
  'Foley Cath Care',
  'Ostomy Care (New placement within past 30 days)',
  'Pleurex Drain',
  'JP Drain or Other Specialty Drain Care',
  'Nephrostomy Tube',
  'Ortho',
  'CABG/Heart Surgery',
  'CHF (New dx within past 30 days)',
  'COPD (New dx within past 30 days)',
  'CVA (New dx within past 30 days)',
  'Diabetes (New Insulin Diabetic Dependent)',
  'Sepsis / UTI',
  'Neuromuscular Restorative and/or Maintenance (I.e., ALS, MS, MD, MG)',
  'Pneumonia / COVID',
  'Musculoskeletal Pain (I.e., back pain)',
];

const patientMockData = {
  transactionId: 'string',
  status: 'pending',
  insurances: [
    {
      hierarchy: 'string',
      payorCode: 'string',
      payor: 'string',
      status: 'string',
      subscriberId: 'string',
      policyStartDate: '2022-11-09T23:29:11.657Z',
      policyEndDate: '2022-11-09T23:29:11.657Z',
      groupNumber: 'string',
      groupName: 'string',
      medicarePartAStart: '2022-11-09T23:29:11.657Z',
      medicarePartAEnd: '2022-11-09T23:29:11.657Z',
      medicarePartBStart: '2022-11-09T23:29:11.657Z',
      medicarePartBEnd: '2022-11-09T23:29:11.657Z',
      receivedFirstName: 'string',
      receivedMiddleName: 'string',
      receivedLastName: 'string',
      receivedSuffix: 'string',
      receivedDateOfBirth: '2022-11-09T23:29:11.657Z',
      receivedSSN: 'string',
      receivedGender: 'string',
      receivedAddress1: 'string',
      receivedAddress2: 'string',
      receivedCity: 'string',
      receivedState: 'string',
      receivedZip: 'string',
      insurancePlan: 'string',
      insuranceType: 'string',
      ipaIdentifier: 'string',
      ipaDescription: 'string',
      mbi: 'string',
      providers: [
        {
          lastName: 'string',
          firstName: 'string',
          description: 'string',
          address1: 'string',
          city: 'string',
          state: 'string',
          zip: 'string',
          phones: ['string'],
        },
      ],
    },
  ],
};

const postReferralData = {
  request: 'string',
  referralId: 0,
  patient: {
    firstName: 'string',
    lastName: 'string',
    street: 'string',
    city: 'string',
    region: 'string',
    postalCode: 'string',
    county: 'string',
    country: 'string',
    latitude: 'string',
    longitude: 'string',
    phone: 'string',
    dob: '2022-11-10T23:06:51.137Z',
    emergecyContactName: 'string',
    emergecyRelationship: 'string',
    emergecyNumber: 'string',
    memberPreferredLanguage: 0,
    memberID: 'string',
    medicareHICN: 'string',
    medicaidID: 'string',
    gender: 0,
  },
  referralSource: {
    name: 'string',
    npi: 'string',
    contact: 'string',
    phone: 'string',
    fax: 'string',
  },
  referralSourceType: 'string',
  referral: {
    requestorEmail: 'user@example.com',
    preferredPHCNProvider: 'string',
    preferredPHCNProviderId: 'string',
    fax: 'string',
    agencyName: 'string',
    agencyPhone: 'string',
    facilityDischargeDate: '2022-11-10T23:06:51.137Z',
    primaryDiagnosis: 'string',
    primaryDiagnosisCode: 'string',
    pastMedicalHistory: 'string',
    patinetsPCPName: 'string',
    patinetsPCPNumber: 'string',
    careType: 'string',
    clinicalGrouping: 'string',
    discipline: 'string',
    additionalOrderDetails: 'string',
    dateOfDC: '2022-11-10T23:06:51.137Z',
    approvedAuthPreference: 0,
  },
  blobFile: [
    {
      source: 'string',
      attachmentFiles: 'string',
      id: 0,
      attachmentFileType: 'string',
      attachmentFileSize: 0,
      uid: 'string',
    },
  ],
  insuranceProvider: {
    healthPlanName: 'string',
    id: '-string',
  },
  referralSpecialCircumstance: {
    covidTestResult: 'string',
    date: '2022-11-10T23:06:51.137Z',
    createdOn: '2022-11-10T23:06:51.137Z',
  },
  physician: {
    orderingPrescriberName: 'string',
    orderingPrescriberNPI: 'string',
    street: 'string',
    city: 'string',
    region: 'string',
    postalCode: 'string',
    county: 'string',
    country: 'string',
    latitude: 'string',
    longitude: 'string',
    phone: 'string',
    fax: 'string',
  },
  dateOfRequest: '2022-11-10T23:06:51.137Z',
  socDate: '2022-11-10T23:06:51.137Z',
  retrospectiveReview: true,
  careType: 'string',
  clinicalGrouping: 'string',
  skilledType: 'string',
  attachments: 'string',
  filesData: ['string'],
  submittedBy: 'string',
};

let referralForm = {
  RetrospectiveReview: 'true',
  Physician: {
    OrderingPrescriberName: '',
    OrderingPrescriberNPI: '',
    Street: '',
    City: '',
    County: '',
    Country: '',
    Latitude: '',
    Longitude: '',
    Phone: '',
    Fax: '',
  },
  Patient: {
    FirstName: '',
    LastName: '',
    Street: '',
    City: '',
    Region: '',
    PostalCode: '',
    County: '',
    Country: '',
    Latitude: '',
    Longitude: '',
    Phone: '',
    DOB: '',
    EmergecyContactName: '',
    EmergecyRelationship: '',
    EmergecyNumber: '',
    MemberPreferredLanguage: 0,
    MemberID: '',
    MedicareHICN: '',
    MedicaidID: '',
    Gender: 0,
  },
  ReferralSource: {
    Name: '',
    NPI: '',
    Contact: '',
    Phone: '',
    Extension: '',
    Fax: '',
  },
  ReferralSourceType: '',
  Referral: {
    RequestorEmail: '',
    PreferredPHCNProvider: '',
    PreferredPHCNProviderId: '',
    Fax: '',
    AgencyName: '',
    AgencyPhone: '',
    FacilityDischargeDate: '',
    PrimaryDiagnosis: '',
    PrimaryDiagnosisCode: '',
    PastMedicalHistory: '',
    PatinetsPCPName: '',
    PatinetsPCPNumber: '',
    CareType: '',
    //Discipline: '',
    AdditionalOrderDetails: '',
    DateOfDC: '',
    ApprovedAuthPreference: '',
  },
  InsuranceProvider: {
    HealthPlanName: '',
    Id: '',
  },
  // BlobFile: [
  //   {
  //     Source: '',
  //     AttachmentFiles: '',
  //     Id: 0,
  //     AttachmentFileType: '',
  //     AttachmentFileSize: 0,
  //     Uid: '',
  //   },
  // ],
  DateOfRequest: '',
  SocDate: '',
  CareType: '',
  ClinicalGrouping: '',
  SkilledType: '',
  Attachments: '',
  FilesData: [],
  SubmittedBy: '',
};

const checkEligibilityValues = {
  Application: 'ProNet',
  RequestDate: currentUTCTime,
  Requestor: 'CPE',
  FirstName: 'Anita',
  LastName: 'Olson',
  DateOfBirth: '1953-08-25',
  ServiceDate: '2023-01-29',
  ProviderId: '',
  SubscriberId: 'H62472946',
};

const requestData = {
  AgencyId: '',
  ApprovedAuthPreference: '',
  City: 'Phoenix',
  ClinicalGroup: 'Ortho',
  County: 'Maricopa County',
  Diagnosis: 'Cold',
  DiagnosisCode: 'PM3',
  Disciplines: [
    {
      label: 'Skilled Nursing',
      value: 'SkilledNursing',
    },
  ],
  Extension: '',
  FacilityName: 'St. Judes',
  Fax: '8465223555',
  GroupName: '',
  InsurancePolicyId: '6454565123',
  LanguagePreferenceValue: 'English',
  MBI: '',
  NPiID: '1336585488',
  Name: 'fasdf dsffs',
  Phone: '8465235445',
  PostalCode: '85033',
  PriorMedicalHistory: '',
  PriorityCareTypes: 'FeedingTube',
  ProviderEmail: '',
  ProviderFax: '',
  ReferralSourceType: 'Hospital',
  SecondaryDiagnosisCode: '',
  StaffingReferralAgencyName: '',
  StartOfCare: '2023-10-05',
  State: 'AZ',
  Urgency: 'Standard',
  UrgencyReason: '',
  attachments: [File],
  dateOfDischarge: '2023-10-09',
  emerContactNumber: '4165451623',
  emerContactRelationship: 'Spouse',
  emergencyContactName: 'rew wrewe',
  healthPlanName: '221',
  opCity: 'Phoenix',
  opCounty: 'Maricopa County',
  opFax: '8795456233',
  opNPI: '1336585488',
  opPhoneNumber: '8458965444',
  opPostalCode: '85037',
  opState: 'AZ',
  opn: 'Dr. Fauci',
  orderingPrescriberSpecialty: '',
  orderingPrescriberTIN: '',
  pcpName: 'Yoshi',
  phoneNumber: '8455658989',
  previousF2FDate: '',
  previousF2FPhysician: '',
  sex: 'Male',
};

export {
  validationSchema,
  initialValues,
  disciplineOptions,
  newinitialValues,
  patientMockData,
  postReferralData,
  referralForm,
  clinicalGroupingList,
  checkEligibilityValues,
  requestData,
};
