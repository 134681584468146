import React, { useEffect } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import SecurePage from "./components/SecurePage";
import CheckStatus from "./components/CheckStatus";
import reportWebVitals from "./reportWebVitals";
import { getConfigs } from "./api";
import { updateConfigs } from "./utility";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppRoot = () => {
  useEffect(() => {
    getConfigs().then((successResponse) => {
      updateConfigs(successResponse);
    });
  }, []);

  return (
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path="/" element={<SecurePage />} />
          <Route path="/eligibility" element={<App />} />
          <Route path="/status" element={<CheckStatus />} />
        </Routes>
      </HashRouter>
    </React.StrictMode>
  );
};

root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
