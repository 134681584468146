import React from 'react';

function Loading() {
  return (
    <>
      <div className='loader'>
        <img
          src='/tangofav.png'
          alt='Loading'
          className='fa fa-spin logo-spin logo-spin'
        />
        <p>Please wait while we are saving your request...</p>
      </div>
    </>
  );
}

export default Loading;
