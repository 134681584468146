import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControlLabel,
  Checkbox,
  Modal,
  Typography,
  TextField,
  FormControl,
  Box,
  Grid,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFormik, Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Footer from './UI/Footer';

const SecurePage = () => {
  const [acknowledge, setAcknowledge] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const history = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #008000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };

  const errorStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FF0000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };
  const validationSchema = yup.object().shape({
    firstName: yup
      .string('Enter your first name.')
      .required('First name is required.'),
    lastName: yup
      .string('Enter your last name.')
      .required('Last name is required.'),
    email: yup
      .string('Enter a valid email.')
      .email('Enter a valid email.')
      .required('Email is required.'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (acknowledge) {
        //const response = await postAgreement(values);

        setOpen(true);
        const timeout = setTimeout(() => {
          setOpen(false);
          history('/eligibility', {
            state: {
              id: 1,
              acknowledge: acknowledge,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
            },
          });
        }, 2000);
        return () => clearTimeout(timeout);
      }
      setErrorOpen(true);
    },
  });

  return (
    <>
      <div className='p-3 logo'>
        <img src='/tangoLogo.png' alt='logo' />
      </div>

      <div className='secure-page-container container text-center mx-auto p-4 mt-4'>
        <h3 className='font-bold text-3xl mb-5 text-PHCN-primary'>
          Welcome to tango's Referral Request Site
        </h3>
        <p className='text-sm mb-3'>
          This is where you will be able to request a referral via tango’s
          secure Referral Request form. Within this form, you will be able to
          check eligibility for Home Health services and complete the necessary
          information to request the referral electronically. Once submitted,
          you will receive a confirmation number that you can use to check
          referral status.
        </p>
        <div className='bg-PHCN-light-blue mt-2 py-2 px-4 border border-PHCN-primary'>
          <div className='py-2'>
            <h3 className='font-bold mb-2'>Privacy and Security</h3>
            <p className='text-sm'>
              tango is committed to maintaining the confidentiality, privacy and
              security of its patients’ protected health information. Consistent
              with the Health Insurance Portability and Accountability Act
              (HIPAA), we do not use, disclose or discuss patient-specific
              information with others unless it is necessary to serve the
              patient or required by law. A copy of our Notice of Privacy
              Practices is provided here. The notice describes how protected
              health information may be used and disclosed and how to access
              PHI.
            </p>
            <p className='text-sm'>
              We are committed to being conscientious and accountable when
              handling confidential company information. In addition, because of
              its confidential nature, business and health information must be
              protected by an effective internal control environment, including
              policies and procedures to secure the company’s assets, and made
              available outside the company only with appropriate authorization
              and after consideration of the interests of tango as a whole.
            </p>
          </div>
          <div>
            <h3 className='font-bold my-2'>Website Content</h3>
            <p className='mb-5 text-sm'>
              tango may change, add or remove some or all of the Content on this
              website at any time. In addition, please note that although our
              goal is to provide accurate information, certain features that may
              be offered through this website, such as participating healthcare
              providers information or other Content, may not be accurate or up
              to date. In addition, please note that features of any plan or
              plans described in this website may change over time as permitted
              by law, including benefit levels, items included in any formulary,
              pricing or lists of participating providers or other associated
              vendors.
            </p>
          </div>
          <div>
            <p className='text-sm '>
              A copy of our Notice of Privacy Practices are provided{' '}
              <a
                className='font-bold underline'
                href='https://prohcn.com/privacy-policy'
                target='_blank'
                rel='noreferrer'
              >
                here
              </a>
            </p>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                value={acknowledge}
                onChange={() => setAcknowledge(!acknowledge)}
              />
            }
          />
          <div className='inline text-sm italic'>
            By clicking I agree, you agree to our Privacy and Website Content
            statements.
          </div>
        </div>
        <div className='mt-5'>
          <p className='text-red-500'>
            If you are a contracted home health provider in tango's network
            please use our{' '}
            <a
              href='https://www.pronetconnect.com/Identity/Account/Login?/index.html'
              target='_blank'
              className='font-bold underline'
              rel='noreferrer'
            >
              ProNet Connect Portal
            </a>{' '}
            or to register for our ProNet Connect Portal, email{' '}
            <a
              href='mailto:providerrelations@tangocare.com'
              target='_blank'
              rel='noreferrer'
              className='font-bold underline'
            >
              providerrelations@tangocare.com
            </a>
          </p>
        </div>

        <div className='border p-4 mt-4'>
          <div className='mb-3'>Name of Requester initiating the request</div>
          <Formik
            initialValues={initialValues}
            onSubmit={formik.handleSubmit}
            validationSchema={validationSchema}
          >
            <Form onSubmit={formik.handleSubmit}>
              <Grid
                container
                direction='row'
                rowGap={2}
                columnGap={2}
                justifyContent='center'
              >
                <Grid item>
                  <FormControl>
                    <Field
                      as={TextField}
                      label='First Name:'
                      variant='filled'
                      className=''
                      size='small'
                      id='firstName'
                      name='firstName'
                      data-testid='firstName'
                      // disabled={eligible}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        required: true,
                      }}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Field
                      as={TextField}
                      label='Last Name:'
                      variant='filled'
                      size='small'
                      id='lastName'
                      name='lastName'
                      // disabled={eligible}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        required: true,
                      }}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Field
                      as={TextField}
                      label='Email:'
                      variant='filled'
                      size='small'
                      id='email'
                      name='email'
                      // disabled={eligible}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        required: true,
                      }}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </div>

        <button
          className='btn primary mt-5 disabled:opacity-25 g-recaptch'
          disabled={acknowledge ? false : true}
          data-testid='startButton'
          onClick={formik.handleSubmit}
          data-sitekey='6LcGjPchAAAAAGzvp-G-606YetJe7qq_gaCGvi-i'
          data-callback='handleSubmit'
          type='submit'
        >
          Start
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='acknowledge-success-modal'
          aria-describedby='acknowledge-success-message'
        >
          <Box sx={style}>
            <CheckBoxIcon fontSize='large' color='success' />
            <Typography
              id='acknowledge-success-message'
              variant='h6'
              component='h2'
            >
              You have successfully agreed to tango's terms and services.
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={errorOpen}
          onClose={handleErrorClose}
          aria-labelledby='acknowledge-error-modal'
          aria-describedby='acknowledge-error-message'
        >
          <Box sx={errorStyle}>
            <CheckBoxIcon fontSize='large' color='error' />
            <Typography
              id='acknowledge-error-message'
              variant='h6'
              component='h2'
              data-testid='error-modal'
            >
              There is a problem saving your agreement. Please try again.
            </Typography>
          </Box>
        </Modal>
        <Footer />
      </div>
    </>
  );
};

export default SecurePage;
