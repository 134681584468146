import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer w-full p-4'>
        <div className="text-center">&copy; Copyright {year} - Powered by <img src="/tangoLogo.png" alt="logo" className='logo-sm' /> </div>
    </footer>
  )
}

export default Footer 