import React from 'react';

const Nav = ({ firstName, lastName }) => {
  // const [confirmationNumber, setConfirmationNumber] = useState('');

  return (
    <>
      <nav className='h-24 flex py-5 px-7 justify-between items-center border-b-2'>
        <div className='logo'>
          <img src='/tangoLogo.png' alt='logo' />
        </div>
        <div className='text-xl italic'>Prior Authorization Form</div>
        <div>
          {lastName}, {firstName}
        </div>
      </nav>
      <nav className='bottom-nav px-7 py-5'>
        {/* <label className='ms-4 me-2'>Check Status: </label>
        <input
          className='form-control noselect mr-4 p-2'
          style={{ borderRadius: '4px' }}
          unselectable='on'
          type='text'
          name='confirmationNumber'
          value={confirmationNumber}
          onChange={(e) => setConfirmationNumber(e.target.value)}
        />

        <button className='btn primary'>Submit</button> */}
      </nav>
    </>
  );
};

export default Nav;
