import axios from 'axios';
import { DateTime } from 'luxon';

import { CONFIG } from './constants';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
const maxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function get(url) {
  try {
    url.replace('undefined', '');
    let response = await maxios.get(url);
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  }
}

export async function post(url, data) {
  url.replace('undefined', '');
  var responseData = await maxios.post(url, data);
  return responseData;
}

export const validateNPI = (value) => {
  let result = false;
  if (value.length === 10) {
    result = luhn('80840'.concat(value));
  }
  return result;
};

export const luhn = (value) => {
  let digitMatch = /^\d+$/;
  let residues = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let result = false;

  if (value && digitMatch.test(value)) {
    let sum = value
      .split('')
      .reverse()
      .map((c) => {
        return parseInt(c);
      })
      .map((c, i) => {
        return i % 2 === 0 ? c : residues[c];
      })
      .reduce((sum, c) => {
        return sum + c;
      }, 0);
    result = sum % 10 === 0;
  }
  return result;
};

export const convertSkilledTypes = (arr) => {
  var returnArray = [];

  arr.map((obj) => {
    return returnArray.push(obj.value);
  });
  return returnArray;
};

export const formatData = (
  values,
  opAddress,
  oPCountry,
  opCoordinates,
  address,
  coordinates,
  patientCountry,
  eligibleValues,
  fileList,
  agencyId,
  submittedBy,
  mbi
) => {
  let disciplines = convertSkilledTypes(values.Disciplines);
  let localTime = DateTime.local();
  let azTime = localTime
    .setZone('America/Phoenix')
    .set({ second: 0, millisecond: 0 });
  let formData = new FormData();
  let submittedByName = submittedBy.lastName + ',' + submittedBy.firstName;
  //Physician
  formData.append('Physician.OrderingPrescriberName', values.opn);
  formData.append('Physician.OrderingPrescriberNPI', values.opNPI);
  formData.append('Physician.Street', opAddress);
  formData.append('Physician.City', values.opCity);
  formData.append('Physician.County', values.opCounty);
  formData.append('Physician.Region', values.opState);
  formData.append('Physician.Country', oPCountry);
  formData.append('Physician.Latitude', opCoordinates.lat);
  formData.append('Physician.Longitude', opCoordinates.lng);
  formData.append('Physician.Phone', values.opPhoneNumber);
  formData.append('Physician.Fax', values.opFax);
  formData.append('Physician.PostalCode', values.opPostalCode);
  formData.append(
    'Physician.OrderingPrescriberTIN',
    values.orderingPrescriberTIN
  );
  formData.append(
    'Physician.OrderingPrescriberSpecialty',
    values.orderingPrescriberSpecialty
  );
  //Agency
  formData.append('AgencyId', agencyId ? agencyId.id : '');
  formData.append('Referral.Fax', values.ProviderFax);
  formData.append('RequestorEmail', values.ProviderEmail);
  formData.append('Agency.ContactName', values.StaffingReferralAgencyName);

  //Referral
  formData.append(
    'Referral.ApprovedAuthPreference',
    values.ApprovedAuthPreference
  );
  formData.append('Referral.PrimaryDiagnosis', values.Diagnosis);
  formData.append('Referral.PrimaryDiagnosisCode', values.DiagnosisCode);
  formData.append(
    'Referral.SecondaryDiagnosisCode',
    values.SecondaryDiagnosisCode
  );
  formData.append('Referral.PastMedicalHistory', null);
  formData.append('Referral.CareType', values.PriorityCareTypes);
  formData.append('Referral.DateOfDC', values.dateOfDischarge);
  formData.append('Referral.PatientsPCPName', values.pcpName);
  formData.append('Referral.Urgency', values.Urgency);
  formData.append('Referral.UrgencyReason', values.UrgencyReason);
  //Referral Source
  formData.append('ReferralSource.Contact', values.Name);
  formData.append('ReferralSource.FacilityType', values.ReferralSourceType);
  formData.append('ReferralSource.Name', values.FacilityName);
  formData.append('ReferralSource.NPI', values.NPiID);
  formData.append('ReferralSourceType', values.ReferralSourceType);
  formData.append('ReferralSource.Phone', values.Phone);
  formData.append('ReferralSource.Fax', values.Fax);
  //Patient information
  formData.append('Patient.FirstName', eligibleValues.FirstName);
  formData.append('Patient.LastName', eligibleValues.LastName);
  formData.append('Patient.Gender', values.sex);
  formData.append('Patient.Street', address);
  formData.append('Patient.City', values.City);
  formData.append('Patient.Region', values.State);
  formData.append('Patient.PostalCode', values.PostalCode);
  formData.append('Patient.County', values.County);
  formData.append('Patient.Latitude', coordinates.lat);
  formData.append('Patient.Longitude', coordinates.lng);
  formData.append('Patient.Country', patientCountry);
  formData.append('Patient.Phone', values.phoneNumber);
  formData.append('Patient.EmergecyContactName', values.emergencyContactName);
  formData.append('Patient.EmergecyNumber', values.emerContactNumber);
  formData.append('PronetUser', 'referralrequest@tangocare.com');
  formData.append(
    'Patient.EmergecyRelationship',
    values.emerContactRelationship
  );
  formData.append(
    'Patient.MemberPreferredLanguage',
    values.LanguagePreferenceValue
  );
  formData.append('Patient.MemberID', values.InsurancePolicyId);
  formData.append('Patient.DOB', eligibleValues.DateOfBirth);
  formData.append('Patient.MedicareHICN', mbi);
  formData.append('Patient.GroupName', values.GroupName);
  //generic values
  formData.append('SocDate', values.StartOfCare);
  formData.append('CareType', values.PriorityCareTypes);
  formData.append('InsuranceProvider.HealthPlanName', values.healthPlanName);
  formData.append('DateOfRequest', azTime);
  formData.append('SubmittedBy', submittedBy.email);
  formData.append('SubmittedByName', submittedByName);
  formData.append('SkilledType', disciplines);
  formData.append('ClinicalGrouping', values.ClinicalGroup);
  formData.append('RetrospectiveReview', '1');
  formData.append('PreviousF2FPhysician', values.previousF2FPhysician);
  formData.append('PreviousF2FDate', values.previousF2FDate);
  for (let i = 0; i < fileList.length; i++) {
    formData.append('BlobFile', fileList[i]);
  }
  return formData;
};

export const formattedAgencyList = (agencyList) => {
  let returnArray = [];
  agencyList?.map((obj) => {
    let newObj = {};

    newObj.label = `${obj.name} - ${obj.address}`;
    newObj.id = obj.id;
    return returnArray.push(newObj);
  });

  return returnArray;
};

export const updateConfigs = (configValues) => {
  if('object' === typeof configValues) {
    Object.keys(configValues).forEach((key) => {
      CONFIG[key] = configValues[key];
    });
  }
}