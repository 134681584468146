import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Formik, Form, Field } from 'formik';
// import { getPayors } from '../api';
import * as yup from 'yup';
// import { checkEligibilityValues } from './data';
import {
  FormControl,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Stepper,
  Step,
  StepLabel,
  Box,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { red } from '@mui/material/colors';
import PriorAuth from './PriorAuth';
import { useEffect } from 'react';
const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          active: {
            color: '#8cc638',
          },
          completed: {
            color: '#8cc638',
          },
        },
      },
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #008000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const errorStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FF0000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const steps = [
  {
    label: 'Patient and Insurance',
  },
  {
    label: 'Check Eligibility',
  },
  {
    label: 'Referral Source',
  },
  {
    label: 'Submitted',
  },
];

const CheckEligibility = (props) => {
  const [open, setOpen] = useState(false);
  const [notEligible, setNotEligible] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  //const [payors, setPayors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [eligible, setEligible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const acknowledge = location?.state?.acknowledge || false;
  const smallScreen = 989;
  const VerticalStepper = () => {
    return !isSmallScreen ? (
      <div className='ml-6 mr-48'>
        <Box sx={{ maxWidth: 400, position: 'fixed' }}>
          <Stepper
            sx={{ color: '#8cc638' }}
            activeStep={activeStep}
            orientation='vertical'
          >
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
    ) : null;
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= smallScreen);
  };

  useEffect(() => {
    if (!acknowledge) {
      navigate('/');
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [acknowledge, navigate]);

  const handleClose = () => {
    setOpen(false);
    setNotEligible(false);
  };

  const date = new Date();
  const currentUTCTime = date.toISOString();
  const initialValues = {
    Application: 'ProNet',
    RequestDate: currentUTCTime,
    Requestor: 'CPE',
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    // ServiceDate: '',
    // ProviderId: '', //payor
    // SubscriberId: '', //policy id
  };
  const validationSchema = yup.object().shape({
    FirstName: yup
      .string('Enter your first name')
      .required('First name is required'),
    LastName: yup
      .string('Enter your last name')
      .required('Last name is required'),
    DateOfBirth: yup
      .date('Enter Date of Birth number')
      .required('Date of Birth is required'),
    // ServiceDate: yup
    //   .date('Enter service begin date')
    //   .required('Service begin date is required'),
    // ProviderId: yup.string('Enter Payor').required('Payor is required'),
    // SubscriberId: yup.string('Enter a valid policy ID'),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      //let patientData = await getIsPatientEligible(values);
      const patientData = { name: 'test' };
      setLoading(false);

      //const isEliglibe = patientData?.data.insurances.length > 0 ? true : false;

      const isEliglibe = true;
      if (isEliglibe) {
        setData(patientData);
        setEligible(true);
        // setOpen(true);
        handleNext();
        handleNext();
      } else {
        setEligible(false);
        setNotEligible(true);
      }
    },
  });

  return (
    <main className='mt-12'>
      <div className='container mx-auto my-5'>
        <div className='text-3xl font-bold text-center my-4'></div>
        <ThemeProvider theme={theme}>
          <div className='flex justify-between'>
            <VerticalStepper />

            <div className='bg p-5'>
              <Formik>
                {() => (
                  <div className='my-2 p-4 md:p-7 form-bg'>
                    <Form
                      onSubmit={formik.handleSubmit}
                      className='my-2 p-4 md:p-7 form-bg '
                    >
                      <div className='mx-auto'>
                        <div className='font-semibold text-sm mb-2'>
                          Member Details:
                        </div>
                        <div className='text-gray-400 text-xs'>
                          Please enter member details to check insurance
                          eligibility.
                        </div>
                        <div className='mb-2'>
                          <span className='text-rose-600 text-xs'>
                            * fields are required
                          </span>
                        </div>
                        <Grid
                          container
                          rowSpacing={4}
                          columnSpacing={{ xs: 1, sm: 2 }}
                        >
                          <Grid item>
                            <FormControl>
                              <div className='md:w-full'>
                                <Field
                                  as={TextField}
                                  label='First Name:'
                                  variant='filled'
                                  size='small'
                                  id='FirstName'
                                  name='FirstName'
                                  disabled={eligible}
                                  value={formik.values.FirstName}
                                  onChange={formik.handleChange}
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                  error={
                                    Boolean(formik.touched.FirstName) &&
                                    Boolean(formik.errors.FirstName)
                                  }
                                  helperText={
                                    formik.touched.FirstName &&
                                    formik.errors.FirstName &&
                                    String(formik.errors.FirstName)
                                  }
                                />
                              </div>
                            </FormControl>
                          </Grid>

                          <Grid item>
                            <FormControl>
                              <div className='md:w-full'>
                                <Field
                                  as={TextField}
                                  label='Last Name:'
                                  variant='filled'
                                  size='small'
                                  id='LastName'
                                  name='LastName'
                                  disabled={eligible}
                                  value={formik.values.LastName}
                                  onChange={formik.handleChange}
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                  error={
                                    formik.touched.LastName &&
                                    Boolean(formik.errors.LastName)
                                  }
                                  helperText={
                                    formik.touched.LastName &&
                                    formik.errors.LastName
                                  }
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl>
                              <Field
                                as={TextField}
                                label='Date of Birth:'
                                variant='filled'
                                size='small'
                                id='DateOfBirth'
                                name='DateOfBirth'
                                disabled={eligible}
                                type='date'
                                value={formik.values.DateOfBirth}
                                InputLabelProps={{
                                  shrink: true,
                                  required: true,
                                }}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.DateOfBirth &&
                                  Boolean(formik.errors.DateOfBirth)
                                }
                                helperText={
                                  formik.touched.DateOfBirth &&
                                  formik.errors.DateOfBirth
                                }
                                //  required
                              />
                            </FormControl>
                          </Grid>
                          {/* <Grid item>
                            <FormControl>
                              <Field
                                as={TextField}
                                label='Service Begin:'
                                variant='filled'
                                size='small'
                                id='ServiceDate'
                                name='ServiceDate'
                                type='date'
                                disabled={eligible}
                                InputLabelProps={{
                                  shrink: true,
                                  required: true,
                                }}
                                value={formik.values.ServiceDate}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.ServiceDate &&
                                  Boolean(formik.errors.ServiceDate)
                                }
                                helperText={
                                  formik.touched.ServiceDate &&
                                  formik.errors.ServiceDate
                                }
                                //  required
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sm={4} md={2}>
                            <FormControl>
                              <div className='md:w-full'>
                                <Field
                                  as={TextField}
                                  label='Policy Id:'
                                  variant='filled'
                                  size='small'
                                  id='SubscriberId'
                                  name='SubscriberId'
                                  disabled={eligible}
                                  value={formik.values.SubscriberId}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.SubscriberId &&
                                    Boolean(formik.errors.SubscriberId)
                                  }
                                  helperText={
                                    formik.touched.SubscriberId &&
                                    formik.errors.SubscriberId
                                  }
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl
                              variant='filled'
                              size='small'
                              sx={{ width: 300 }}
                            >
                              <InputLabel id='ProviderId'>
                                Payor <span className='text-rose-400'>*</span>
                              </InputLabel>
                              <Select
                                labelId='ProviderId'
                                id='ProviderId'
                                name='ProviderId'
                                //required
                                value={formik.values.ProviderId}
                                label='Payor'
                                disabled={eligible}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.ProviderId &&
                                  Boolean(formik.errors.ProviderId)
                                }
                              >
                                {payors.map((payor) => (
                                  <MenuItem key={payor.id} value={payor.id}>
                                    {payor.name}
                                  </MenuItem>
                                ))}
                              </Select>

                              <FormHelperText>
                                * If payor is not in the list, we are not
                                contracted with them.
                              </FormHelperText>
                              <FormHelperText>
                                <span className='text-red-500'>
                                  {formik.touched.ProviderId &&
                                    formik.errors.ProviderId}
                                </span>
                              </FormHelperText>
                            </FormControl>
                          </Grid> */}
                        </Grid>

                        <div className='mx-auto mt-5 flex justify-center'>
                          <button
                            className='btn primary disabled:opacity-25'
                            type='submit'
                            name='CheckEligibility'
                            style={{ marginRight: '10px' }}
                            disabled={eligible ? true : false}
                          >
                            Continue{' '}
                          </button>
                        </div>
                        {loading ? (
                          <div
                            data-testid='loader'
                            className='mx-auto flex justify-center mt-5'
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>

              {eligible ? (
                <PriorAuth
                  handleNext={handleNext}
                  patient={data}
                  activeStep={activeStep}
                  handleReset={handleReset}
                  eligibleValues={formik.values}
                  submittedBy={location.state}
                  isSmallScreen={isSmallScreen}
                />
              ) : (
                ''
              )}
            </div>
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='success-modal'
            aria-describedby='patient-is-eligible'
          >
            <Box sx={style} autohideduration={3000}>
              <CheckBoxIcon fontSize='large' color='success' />
              <Typography
                id='success-modal'
                data-testid='eligible-modal'
                variant='h6'
                component='h2'
              >
                The patient is eligible for care.
              </Typography>
              <Typography id='patient-is-eligible' sx={{ mt: 2 }}>
                Some values will be auto-populated for the patient. Please click
                outside the modal to close it.
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={notEligible}
            onClose={handleClose}
            aria-labelledby='ineligible-modal'
            aria-describedby='patient-is-not-eligible'
          >
            <Box sx={errorStyle}>
              <DoDisturbOnIcon fontSize='large' sx={{ color: red[500] }} />
              <Typography
                id='ineligible-modal'
                data-testid='ineligible-modal'
                variant='h6'
                component='h2'
              >
                This patient is not managed by PHCN.
              </Typography>
              <Typography id='patient-is-not-eligible' sx={{ mt: 2 }}>
                If you have any questions please feel free to contact our main
                line at: (888) 705-5274.
              </Typography>
            </Box>
          </Modal>
        </ThemeProvider>
      </div>
    </main>
  );
};

export default CheckEligibility;
