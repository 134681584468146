import React, { useState, useRef } from 'react';
import { useFormik, Formik, Form, Field } from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    Box,
    InputLabel,
    Select,
    MenuItem,
    //Autocomplete,
    Modal,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { red } from '@mui/material/colors';
import { MultiSelect } from 'react-multi-select-component';
import { FocusError } from 'focus-formik-error';
import { ingestReferral } from '../api';
import { validateNPI } from '../utility';
import SignatureCanvas from 'react-signature-canvas';
import {
    validationSchema,
    initialValues,
    disciplineOptions,
    // postReferralData,
    // newinitialValues,
    clinicalGroupingList,
} from './mocks/data';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
//import { useEffect } from 'react';
import Loading from './UI/Loading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #008000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

const errorStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FF0000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

const mbiRegex =
    /^[0-9][A-Z][0-9A-Z][0-9][A-Z][0-9A-Z][0-9][A-Z][A-Z][0-9][0-9]$/;
const MBIErrorMessage =
    'Incorrect format for MBI number. Requirements are as follows:\n' +
    '\n' +
    '- MBI has 11 characters (numeric and alpha characters)\n' +
    '\n' +
    '- No dashes are used in the MBI\n' +
    '\n' +
    '- Characters 2nd, 5th, 8th, and 9th characters are always letters\n' +
    '\n' +
    '- Characters 1, 4, 7, 10, and 11 are always numbers\n' +
    '\n' +
    '- The 3rd and 6th characters are letters or numbers';

const PriorAuth = ({
    handleNext,
    patient,
    activeStep,
    handleReset,
    eligibleValues,
    submittedBy,
    isSmallScreen,
}) => {
    const [selected, setSelected] = useState([]);
    const [referralSourceNPI, setReferralSourceNPI] = useState('');
    const [referralSourceNPIError, setReferralSourceNPIError] = useState(false);
    const [orderingPrescriberNPI, setOrderingPrescriberNPI] = useState('');
    const [orderingPrescriberNPIError, setOrderingPrescriberNPIError] =
        useState(false);
    const [fileList, setFileList] = useState([]);
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [opAddress, setOpAddress] = useState('');
    const [opAddressError, setOpAddressError] = useState(false);
    const [oPCountry, setOPCountry] = useState('');
    const [opCoordinates, setOPCoordinates] = useState({});
    const [patientCountry, setPatientCountry] = useState('');
    const [coordinates, setCoordinates] = useState({});
    const [referralPostSuccessful, setReferralPostSuccessful] = useState(false);
    const [referralPostError, setReferralPostError] = useState(false);
    const [successfulReferralId, setSuccessfulReferralId] = useState('');
    //const [agencyId, setAgencyId] = useState(null);
    //const [agencyList, setAgencyList] = useState([]);
    const [signature, setSignature] = useState('');
    const [signatureError, setSignatureError] = useState(null);
    const [signatureSaved, setSignatureSaved] = useState(null);
    const [loading, setLoading] = useState(false);
    const [certify, setCertify] = useState(false);
    const [mbi, setMBI] = useState('');
    const [mbiError, setMBIError] = useState(false);

    //useEffect(() => {
    //    async function fetchLists() {
    //        let response = await getAgencyList();
    //        const payorsResponse = await getPayors();
    //        setAgencyList(response);
    //        setPayors(payorsResponse);
    //    }
    //    fetchLists();
    //}, []);

    const handleDisciplineSelect = (selectedOptions) => {
        setSelected(selectedOptions);
    };

    const handleSuccessClose = () => {
        setReferralPostSuccessful(false);
        const timeout = setTimeout(() => {
            window.location.reload();
        }, 2500);

        return () => clearTimeout(timeout);
    };

    const handleErrorClose = () => {
        setReferralPostError(false);
    };

    const handleMBIChange = (event) => {
        const value = event.target.value;
        setMBI(value);

        // Define the regular expression for MBI validation
        const mbiRegex =
            /^[0-9][A-Z][0-9A-Z][0-9][A-Z][0-9A-Z][0-9][A-Z][A-Z][0-9][0-9]$/;

        // Validate the value using the regex
        const isValid = mbiRegex.test(value);

        // Update the error state based on the validation result
        setMBIError(!isValid);
    };

    const sigCanvas = useRef({});
    const clear = () => {
        setSignatureSaved(null);
        setSignature('');
        sigCanvas.current.clear();
    };
    const save = () => {
        if (sigCanvas.current.isEmpty()) {
            setSignatureError('A signature is required.');
            setSignatureSaved(null);
            return;
        }
        setSignatureError(null);
        setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        setSignatureSaved('Signature is saved.');
    };

    const formatData = (values, opAddress, oPCountry, opCoordinates, address, coordinates, patientCountry, eligibleValues, fileList, submittedBy, mbi) => {
        return {
            values: values,
            operationAddress: opAddress,
            operationCountry: oPCountry,
            operationCoordinates: opCoordinates,
            address: address,
            coordinates: coordinates,
            patientCountry: patientCountry,
            eligibleValues: eligibleValues,
            files: fileList,
            submittedBy: submittedBy,
            mbi: mbi
        };
    }

    const formik = useFormik({
        //initialValues: newinitialValues,
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            if (signature && certify) {
                setLoading(true);
                const { el, errors } = validateCustomFields(
                    values,
                    setErrors,
                    setSubmitting
                );

                if (Object.keys(errors).length !== 0) {
                    el.focus();
                    setLoading(false);
                    return;
                }

                try {
                    const referralId = await ingestReferral(
                        formatData(values, opAddress, oPCountry, opCoordinates, address, coordinates, patientCountry, eligibleValues, fileList, submittedBy, mbi),
                        submittedBy,
                        signature,
                        fileList
                    );

                    if (typeof referralId === 'string' && referralId.trim().length > 0) {
                        console.log(`succcess: true`);
                        setSuccessfulReferralId(referralId);
                        setReferralPostSuccessful(true);
                        handleNext();
                        setLoading(false);
                        return formik.resetForm();
                    }
                    setLoading(false);
                    setReferralPostError(true);

                    console.log('Referral Processed:', referralId);
                } catch (error) {
                    console.error('Failed to process referral:', error);
                }
            } else {
                console.log(`succcess: false`);
                setLoading(false);
                setSignatureError(
                    'Please certify the information you entered is correct and sign the form.'
                );
                return;
            }
        },
    });

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const { address_components } = results[0];
        const li = await getLatLng(results[0]);
        let streetAddress = '';
        let postcode = '';
        for (const component of address_components) {
            const type = component.types[0];

            switch (type) {
                case 'street_number': {
                    streetAddress = `${component.long_name} ${streetAddress}`;
                    break;
                }
                case 'route': {
                    streetAddress += component.short_name;
                    break;
                }

                case 'postal_code': {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case 'locality':
                    formik.values.City = component.long_name;

                    break;

                case 'administrative_area_level_2': {
                    formik.values.County = component.short_name;

                    break;
                }
                case 'administrative_area_level_1': {
                    formik.values.State = component.short_name;
                    break;
                }
                case 'country':
                    setPatientCountry(component.long_name);

                    break;
                default:
                    console.log('no selection');
            }
        }
        formik.values.PostalCode = postcode;
        setAddress(streetAddress);
        setCoordinates(li);
    };

    const handleOPSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const { address_components } = results[0];
        const li = await getLatLng(results[0]);
        let streetAddress = '';
        let postcode = '';
        for (const component of address_components) {
            const type = component.types[0];

            switch (type) {
                case 'street_number': {
                    streetAddress = `${component.long_name} ${streetAddress}`;
                    break;
                }
                case 'route': {
                    streetAddress += component.short_name;
                    break;
                }

                case 'postal_code': {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }
                case 'locality':
                    formik.values.opCity = component.long_name;

                    break;

                case 'administrative_area_level_2': {
                    formik.values.opCounty = component.short_name;

                    break;
                }
                case 'administrative_area_level_1': {
                    formik.values.opState = component.short_name;
                    break;
                }
                case 'country': {
                    setOPCountry(component.long_name);

                    break;
                }

                default:
                    console.log('no selection');
            }
        }
        formik.values.opPostalCode = postcode;
        setOpAddress(streetAddress);
        setOPCoordinates(li);
    };

    const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200 MB
    const FILE_NAME_REGEX = new RegExp(/^[a-zA-Z0-9_.\W+]+$/);

    const updateList = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(
            (file) => file.size <= MAX_FILE_SIZE && FILE_NAME_REGEX.test(file.name)
        );

        // Sanitize the filenames
        const sanitizedFiles = validFiles.map((file) => {
            const sanitizedName = file.name.replace(/[^\w\s.]/g, '_');
            // Create a new File object with the sanitized name
            return new File([file], sanitizedName, { type: file.type });
        });

        // Set the sanitized files in the fileList
        setFileList(sanitizedFiles);
    };

    const removeFile = (fileToRemove) => {
        const newFiles = fileList.filter((file) => file.name !== fileToRemove.name);
        setFileList(newFiles);
    };

    const validateCustomFields = (values, setErrors, setSubmitting) => {
        console.log(`validation start`);
        const customErrors = {};
        let refSourceNPI = document.getElementById('referralSourceNPI');
        let opNPI = document.getElementById('opNPI');
        let memberAddress = document.getElementById('member-address');
        let opStreet = document.getElementById('opStreet');
        let mbiInput = document.getElementById('MedicareHICN');

        if (!refSourceNPI.value) {
            customErrors.NPiID = 'Referral Source NPI is required.';
            setReferralSourceNPIError(true);
            setErrors(customErrors);
            console.log(`validation failed at refSourceNPI.`);
            return {
                el: refSourceNPI,
                errors: customErrors,
            };
        } else if (!validateNPI(refSourceNPI.value)) {
            customErrors.NPiID = 'Referral Source NPI is not valid.';
            setReferralSourceNPIError(true);
            setErrors(customErrors);
            console.log(`validation failed at validateNPI.`);
            return {
                el: refSourceNPI,
                errors: customErrors,
            };
        }

        if (!address) {
            customErrors.Street = 'Member address is required.';
            setAddressError(true);
            setErrors(customErrors);
            console.log(`validation failed at address.`);
            return {
                el: memberAddress,
                errors: customErrors,
            };
        }

        if (!opAddress) {
            customErrors.opStreet = 'Ordering prescriber address is required.';
            setOpAddressError(true);
            setErrors(customErrors);
            console.log(`validation failed at opAddress.`);
            return {
                el: opStreet,
                errors: customErrors,
            };
        }

        if (!opNPI.value) {
            customErrors.opNPI = 'Ordering Prescriber NPI is required.';
            setOrderingPrescriberNPIError(true);
            setErrors(customErrors);
            console.log(`validation failed at opNPI #1.`);
            return {
                el: opNPI,
                errors: customErrors,
            };
        } else if (!validateNPI(opNPI.value)) {
            customErrors.opNPI = 'Ordering Prescriber NPI is not valid.';
            console.log(opNPI, 'opNPI');
            setOrderingPrescriberNPIError(true);
            setErrors(customErrors);
            console.log(`validation failed at opNPI #2.`);
            return {
                el: opNPI,
                errors: customErrors,
            };
        }

        if (mbiInput.value) {
            const isValidMBI = mbiRegex.test(mbiInput.value);

            if (!isValidMBI) {
                customErrors.MBI = MBIErrorMessage;
                setMBIError(true);
                setErrors(customErrors);
                console.log(`validation failed at mbiInput.`);
                return {
                    el: mbiInput,
                    errors: customErrors,
                };
            }
        }

        if (
            formik.values.Urgency === 'Expedited' &&
            formik.values.UrgencyReason === ''
        ) {
            customErrors.UrgencyReason = 'Urgency Reason is required.';
            setErrors(customErrors);
            console.log(`validation failed at urgency reason.`);
            return {
                el: document.getElementById('urgency-reason'),
                errors: customErrors,
            };
        }

        if (selected.length === 0) {
            customErrors.Disciplines = 'Skilled Disciplines is required.';
            setErrors(customErrors);
            console.log(`validation failed at skilled disciplines.`);
            const el = document.getElementsByClassName('dropdown-container')[0];
            return {
                el,
                errors: customErrors,
            };
        }

        if (fileList.length < 1) {
            customErrors.attachments = 'Please upload required attachments.';
            setErrors(customErrors);
            console.log(`validation failed at attachments.`);
            const el = document.getElementById('attachments');
            return {
                el,
                errors: customErrors,
            };
        }

        //values.AgencyId = agencyId;
        values.Disciplines = selected;
        values.attachments = fileList;
        values.NPiID = referralSourceNPI;
        values.opNPI = orderingPrescriberNPI;
        values.MBI = mbi;

        return {
            el: false,
            errors: {},
        };
    };

    return (
        <>
            {loading && <Loading />}
            <Formik initialValues={initialValues} validationSchema={validationSchema}>
                {() => (
                    <Form
                        onSubmit={formik.handleSubmit}
                        className='my-2 p-4 md:p-7 form-bg'
                        method='post'
                    >
                        <FocusError
                            formik={formik}
                            offset={0}
                            align={'top'}
                            focusDelay={200}
                            ease={'linear'}
                            duration={1000}
                        />
                        <div className='mx-auto'>
                            <div className='font-bold text-md mb-1'>
                                Enter the following information.
                            </div>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 0, sm: 2 }}>
                                {/* Referral Source Information */}
                                <Grid item container>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Referral Source:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-2'>
                                            Please enter the referral source information
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                    >
                                        <Grid item>
                                            <FormControl
                                                sx={{ minWidth: 200 }}
                                                variant='filled'
                                                size='small'
                                            // data-testid='ReferralSourceType'
                                            >
                                                <InputLabel id='ReferralSourceType'>
                                                    Facility Type <span className='text-rose-400'>*</span>
                                                </InputLabel>
                                                <Select
                                                    id='ReferralSourceType'
                                                    data-testid='ReferralSourceType'
                                                    name='ReferralSourceType'
                                                    value={formik.values.ReferralSourceType}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.ReferralSourceType &&
                                                        Boolean(formik.errors.ReferralSourceType)
                                                    }
                                                >
                                                    <MenuItem
                                                        data-testid='ReferralSourceeType'
                                                        value='Hospital'
                                                    >
                                                        Hospital
                                                    </MenuItem>
                                                    <MenuItem value='SkilledNursingFacility'>
                                                        SNF/Rehab
                                                    </MenuItem>
                                                    <MenuItem value='DoctorsOffice'>MD Office</MenuItem>
                                                    <MenuItem value='Beneficiary'>Beneficiary</MenuItem>
                                                    <MenuItem value='Unknown'>Other</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    <span className='text-red-500'>
                                                        {formik.touched.ReferralSourceType &&
                                                            formik.errors.ReferralSourceType}
                                                    </span>
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Referring Facility Name:'
                                                    variant='filled'
                                                    size='small'
                                                    id='FacilityName'
                                                    name='FacilityName'
                                                    type='text'
                                                    value={formik.values.FacilityName}
                                                    InputLabelProps={{ required: true }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.FacilityName &&
                                                        Boolean(formik.errors.FacilityName)
                                                    }
                                                    helperText={
                                                        formik.touched.FacilityName &&
                                                        formik.errors.FacilityName
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='NPI:'
                                                    variant='filled'
                                                    size='small'
                                                    id='referralSourceNPI'
                                                    name='referralSourceNPI'
                                                    type='text'
                                                    value={referralSourceNPI}
                                                    InputLabelProps={{ required: true }}
                                                    inputProps={{ maxLength: 10 }}
                                                    onChange={(e) => setReferralSourceNPI(e.target.value)}
                                                    error={
                                                        referralSourceNPIError &&
                                                        !referralSourceNPI &&
                                                        'Please enter valid NPI number'
                                                    }
                                                    helperText={
                                                        referralSourceNPIError &&
                                                        !referralSourceNPI &&
                                                        'Please enter valid NPI number'
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Contact:'
                                                    variant='filled'
                                                    size='small'
                                                    id='Name'
                                                    name='Name'
                                                    type='text'
                                                    value={formik.values.Name}
                                                    InputLabelProps={{ required: true }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.Name && Boolean(formik.errors.Name)
                                                    }
                                                    helperText={formik.touched.Name && formik.errors.Name}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Phone:'
                                                    variant='filled'
                                                    size='small'
                                                    id='Phone'
                                                    name='Phone'
                                                    type='text'
                                                    value={formik.values.Phone}
                                                    InputLabelProps={{ required: true }}
                                                    inputProps={{ maxLength: 10 }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.Phone && Boolean(formik.errors.Phone)
                                                    }
                                                    helperText={
                                                        formik.touched.Phone && formik.errors.Phone
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Extension:'
                                                    variant='filled'
                                                    size='small'
                                                    id='Extension'
                                                    name='Extension'
                                                    type='text'
                                                    value={formik.values.Extension}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.Extension &&
                                                        Boolean(formik.errors.Extension)
                                                    }
                                                    helperText={
                                                        formik.touched.Extension && formik.errors.Extension
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Fax:'
                                                    variant='filled'
                                                    size='small'
                                                    id='ReferralSourceFax'
                                                    data-testid='ReferralSourceFax'
                                                    name='Fax'
                                                    type='text'
                                                    value={formik.values.Fax}
                                                    InputLabelProps={{ required: true }}
                                                    inputProps={{ maxLength: 10 }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.Fax && Boolean(formik.errors.Fax)
                                                    }
                                                    helperText={formik.touched.Fax && formik.errors.Fax}
                                                />
                                            </FormControl>
                                            <FormHelperText>
                                                PHI could be faxed to this number
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Date Request / Soc Date */}

                                <Grid item container>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Member Details:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-4'>
                                            Please add Member Details that is present in the referral
                                            details and confirmed by patient.
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                    >
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Requested SOC Date:'
                                                    variant='filled'
                                                    size='small'
                                                    id='StartOfCare'
                                                    name='StartOfCare'
                                                    type='date'
                                                    value={formik.values.StartOfCare}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    onChange={formik.handleChange}
                                                    data-testid='requested-soc-date-input'
                                                    error={
                                                        formik.touched.StartOfCare &&
                                                        Boolean(formik.errors.StartOfCare)
                                                    }
                                                    helperText={
                                                        formik.touched.StartOfCare &&
                                                        formik.errors.StartOfCare
                                                    }
                                                // required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Address */}
                                <Grid
                                    item
                                    container
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 0, sm: 2 }}
                                    justifyContent=''
                                >
                                    <Grid item>
                                        <FormControl>
                                            <div className='md:w-full'>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={setAddress}
                                                    onSelect={handleSelect}
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (
                                                        <div>
                                                            <Field
                                                                as={TextField}
                                                                {...getInputProps()}
                                                                label='Member Street Name:'
                                                                variant='filled'
                                                                name='member-address'
                                                                size='small'
                                                                id='member-address'
                                                                InputLabelProps={{
                                                                    required: true,
                                                                }}
                                                                error={
                                                                    !address &&
                                                                    addressError &&
                                                                    'Please enter valid address'
                                                                }
                                                                helperText={
                                                                    !address &&
                                                                    addressError &&
                                                                    'Please enter valid address'
                                                                }
                                                            />
                                                            <div className='autocomplete-dropdown-container'>
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map((suggestion, key) => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    const style = suggestion.active
                                                                        ? {
                                                                            backgroundColor: '#fafafa',
                                                                            cursor: 'pointer',
                                                                        }
                                                                        : {
                                                                            backgroundColor: '#ffffff',
                                                                            cursor: 'pointer',
                                                                        };
                                                                    return (
                                                                        <div
                                                                            key={key}
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <div className='md:w-full'>
                                                <Field
                                                    as={TextField}
                                                    disabled
                                                    label='Member City:'
                                                    variant='filled'
                                                    size='small'
                                                    id='City'
                                                    name='City'
                                                    value={formik.values.City}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    error={
                                                        formik.touched.City && Boolean(formik.errors.City)
                                                    }
                                                    helperText={formik.touched.City && formik.errors.City}
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <div className='md:w-full'>
                                                <Field
                                                    as={TextField}
                                                    disabled
                                                    label='Member State:'
                                                    variant='filled'
                                                    size='small'
                                                    id='State'
                                                    name='State'
                                                    value={formik.values.State}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    error={
                                                        formik.touched.State && Boolean(formik.errors.State)
                                                    }
                                                    helperText={
                                                        formik.touched.State && formik.errors.State
                                                    }
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <div className='md:w-full'>
                                                <Field
                                                    as={TextField}
                                                    disabled
                                                    label='Member County:'
                                                    variant='filled'
                                                    size='small'
                                                    id='County'
                                                    name='County'
                                                    value={formik.values.County}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    error={
                                                        formik.touched.County &&
                                                        Boolean(formik.errors.County)
                                                    }
                                                    helperText={
                                                        formik.touched.County && formik.errors.County
                                                    }
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <div className='md:w-full'>
                                                <Field
                                                    as={TextField}
                                                    disabled
                                                    label='Member Postal Code:'
                                                    variant='filled'
                                                    size='small'
                                                    id='PostalCode'
                                                    name='PostalCode'
                                                    value={formik.values.PostalCode}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    error={
                                                        formik.touched.PostalCode &&
                                                        Boolean(formik.errors.PostalCode)
                                                    }
                                                    helperText={
                                                        formik.touched.PostalCode &&
                                                        formik.errors.PostalCode
                                                    }
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <div className='md:w-full'>
                                                <Field
                                                    as={TextField}
                                                    label='Member Phone:'
                                                    variant='filled'
                                                    size='small'
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    value={formik.values.phoneNumber}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    inputProps={{ maxLength: 10 }}
                                                    error={
                                                        formik.touched.phoneNumber &&
                                                        Boolean(formik.errors.phoneNumber)
                                                    }
                                                    helperText={
                                                        formik.touched.phoneNumber &&
                                                        formik.errors.phoneNumber
                                                    }
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl
                                            sx={{ minWidth: 125 }}
                                            variant='filled'
                                            size='small'
                                        >
                                            <InputLabel id='Gender'>
                                                Gender <span className='text-rose-400'>*</span>
                                            </InputLabel>
                                            <Select
                                                id='sex'
                                                name='sex'
                                                value={formik.values.sex}
                                                onChange={formik.handleChange}
                                                error={formik.touched.sex && Boolean(formik.errors.sex)}
                                            >
                                                <MenuItem value='Male'>Male</MenuItem>
                                                <MenuItem value='Female'>Female</MenuItem>
                                                <MenuItem value='Unknown'>Unknown</MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                <span className='text-red-500'>
                                                    {formik.touched.sex && formik.errors.sex}
                                                </span>
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl
                                            sx={{ minWidth: 270 }}
                                            variant='filled'
                                            size='small'
                                        >
                                            <InputLabel id='language'>
                                                Member Preferred Language{' '}
                                                <span className='text-rose-400'>*</span>
                                            </InputLabel>
                                            <Select
                                                labelId='LanguagePreferenceValue'
                                                id='LanguagePreferenceValue'
                                                name='LanguagePreferenceValue'
                                                value={formik.values.LanguagePreferenceValue}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.LanguagePreferenceValue &&
                                                    Boolean(formik.errors.LanguagePreferenceValue)
                                                }
                                            >
                                                <MenuItem value='English'>English</MenuItem>
                                                <MenuItem value='Spanish'>Spanish</MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                <span className='text-red-500'>
                                                    {formik.touched.LanguagePreferenceValue &&
                                                        formik.errors.LanguagePreferenceValue}
                                                </span>
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Plan Details */}
                                <Grid item container>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Plan Details:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-2'>
                                            Please add the insurance plan details availiable from the
                                            referral source / patient record.
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                    >
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Health Plan Name:'
                                                    variant='filled'
                                                    size='small'
                                                    id='healthPlanName'
                                                    name='healthPlanName'
                                                    value={formik.values.healthPlanName}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.healthPlanName &&
                                                        Boolean(formik.errors.healthPlanName)
                                                    }
                                                    helperText={
                                                        formik.touched.healthPlanName &&
                                                        formik.errors.healthPlanName
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Insurance Policy ID:'
                                                    variant='filled'
                                                    size='small'
                                                    id='InsurancePolicyId'
                                                    name='InsurancePolicyId'
                                                    value={formik.values.InsurancePolicyId}
                                                    InputLabelProps={{
                                                        required: true,
                                                    }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.InsurancePolicyId &&
                                                        Boolean(formik.errors.InsurancePolicyId)
                                                    }
                                                    helperText={
                                                        formik.touched.InsurancePolicyId &&
                                                        formik.errors.InsurancePolicyId
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Group Name/Number:'
                                                    variant='filled'
                                                    size='small'
                                                    id='GroupName'
                                                    name='GroupName'
                                                    value={formik.values.GroupName}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.GroupName &&
                                                        Boolean(formik.errors.GroupName)
                                                    }
                                                    helperText={
                                                        formik.touched.GroupName && formik.errors.GroupName
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <FormControl sx={{ maxWidth: 285 }}>
                                                <Field
                                                    as={TextField}
                                                    label='MBI:'
                                                    variant='filled'
                                                    size='small'
                                                    id='MedicareHICN'
                                                    name='MedicareHICN'
                                                    value={mbi}
                                                    onChange={handleMBIChange}
                                                    inputProps={{ maxLength: 11 }}
                                                    error={Boolean(mbiError)} // Set error based on presence of error message
                                                    helperText={
                                                        mbiError && ( // Display the error message conditionally
                                                            <span className='error-message'>
                                                                Incorrect format for MBI number. Requirements
                                                                are as follows:
                                                                <br />
                                                                - MBI has 11 characters (numeric and alpha
                                                                characters)
                                                                <br />
                                                                - No dashes are used in the MBI
                                                                <br />
                                                                - Characters 2nd, 5th, 8th, and 9th characters
                                                                are always letters
                                                                <br />
                                                                - Characters 1, 4, 7, 10, and 11 are always
                                                                numbers
                                                                <br />- The 3rd and 6th characters are letters
                                                                or numbers
                                                            </span>
                                                        )
                                                    }
                                                />
                                                <FormHelperText>
                                                    *Medicare Beneficiary Identifier Number
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Emergency Contact Details */}
                                <Grid item container>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Emergency Contact Details:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-2'>
                                            Please add the emergency contact information for the
                                            patient.
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                    >
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='Contact Name:'
                                                        variant='filled'
                                                        size='small'
                                                        id='emergencyContactName'
                                                        name='emergencyContactName'
                                                        value={formik.values.emergencyContactName}
                                                        onChange={formik.handleChange}
                                                        error={
                                                            formik.touched.emergencyContactName &&
                                                            Boolean(formik.errors.emergencyContactName)
                                                        }
                                                        helperText={
                                                            formik.touched.emergencyContactName &&
                                                            formik.errors.emergencyContactName
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl
                                                variant='filled'
                                                size='small'
                                                sx={{ width: 180 }}
                                            >
                                                <InputLabel id='language'>Relationship</InputLabel>
                                                <Select
                                                    labelId='emerContactRelationship'
                                                    id='emerContactRelationship'
                                                    name='emerContactRelationship'
                                                    value={formik.values.emerContactRelationship}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.emerContactRelationship &&
                                                        Boolean(formik.errors.emerContactRelationship)
                                                    }
                                                >
                                                    <MenuItem value='Self'>Self</MenuItem>
                                                    <MenuItem value='Spouse'>Spouse</MenuItem>
                                                    <MenuItem value='Parent'>Parent</MenuItem>
                                                    <MenuItem value='Child'>Child</MenuItem>
                                                    <MenuItem value='Sibling'>Sibling</MenuItem>
                                                    <MenuItem value='Grandparent'>Grandparent</MenuItem>
                                                    <MenuItem value='Grandchild'>Grandchild</MenuItem>
                                                    <MenuItem value='Aunt/Uncle'>Aunt/Uncle</MenuItem>
                                                    <MenuItem value='Niece/Nephew'>Niece/Nephew</MenuItem>
                                                    <MenuItem value='Friend'>Friend</MenuItem>
                                                    <MenuItem value='Other'>Other</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    <span className='text-red-500'>
                                                        {formik.touched.emerContactRelationship &&
                                                            formik.errors.emerContactRelationship}
                                                    </span>
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Contact Number:'
                                                    variant='filled'
                                                    size='small'
                                                    id='emerContactNumber'
                                                    name='emerContactNumber'
                                                    value={formik.values.emerContactNumber}
                                                    onChange={formik.handleChange}
                                                    inputProps={{ maxLength: 10 }}
                                                    error={
                                                        formik.touched.emerContactNumber &&
                                                        Boolean(formik.errors.emerContactNumber)
                                                    }
                                                    helperText={
                                                        formik.touched.emerContactNumber &&
                                                        formik.errors.emerContactNumber
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Ordering Prescriber Details:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-2'>
                                            Please enter the Ordering Prescriber information.
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                    >
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='OP Name:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opn'
                                                        name='opn'
                                                        value={formik.values.opn}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        error={
                                                            formik.touched.opn && Boolean(formik.errors.opn)
                                                        }
                                                        helperText={formik.touched.opn && formik.errors.opn}
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='OP NPI:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opNPI'
                                                        name='opNPI'
                                                        value={orderingPrescriberNPI}
                                                        onChange={(e) => {
                                                            setOrderingPrescriberNPI(e.target.value);
                                                            setOrderingPrescriberNPIError(false);
                                                        }}
                                                        InputLabelProps={{ required: true }}
                                                        inputProps={{ maxLength: 10 }}
                                                        error={
                                                            orderingPrescriberNPIError &&
                                                            'Please enter valid NPI number'
                                                        }
                                                        helperText={
                                                            orderingPrescriberNPIError &&
                                                            'Please enter valid NPI number'
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='OP TIN:'
                                                        variant='filled'
                                                        size='small'
                                                        id='orderingPrescriberTIN'
                                                        name='orderingPrescriberTIN'
                                                        value={formik.values.orderingPrescriberTIN}
                                                        inputProps={{ maxLength: 9 }}
                                                        onChange={formik.handleChange}
                                                        error={
                                                            formik.touched.orderingPrescriberTIN &&
                                                            Boolean(formik.errors.orderingPrescriberTIN)
                                                        }
                                                        helperText={
                                                            formik.touched.orderingPrescriberTIN &&
                                                            formik.errors.orderingPrescriberTIN
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='Specialty:'
                                                        variant='filled'
                                                        size='small'
                                                        id='orderingPrescriberSpecialty'
                                                        name='orderingPrescriberSpecialty'
                                                        value={formik.values.orderingPrescriberSpecialty}
                                                        onChange={formik.handleChange}
                                                        inputProps={{ maxLength: 25 }}
                                                        error={
                                                            formik.touched.orderingPrescriberSpecialty &&
                                                            Boolean(formik.errors.orderingPrescriberSpecialty)
                                                        }
                                                        helperText={
                                                            formik.touched.orderingPrescriberSpecialty &&
                                                            formik.errors.orderingPrescriberSpecialty
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <PlacesAutocomplete
                                                        value={opAddress}
                                                        onChange={setOpAddress}
                                                        onSelect={handleOPSelect}
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading,
                                                        }) => (
                                                            <div>
                                                                <Field
                                                                    as={TextField}
                                                                    {...getInputProps()}
                                                                    label='Street Name:'
                                                                    variant='filled'
                                                                    name='prescriber-address'
                                                                    size='small'
                                                                    id='opStreet'
                                                                    InputLabelProps={{
                                                                        required: true,
                                                                    }}
                                                                    error={
                                                                        opAddressError &&
                                                                        !opAddress &&
                                                                        'Please enter valid address'
                                                                    }
                                                                    helperText={
                                                                        opAddressError &&
                                                                        !opAddress &&
                                                                        'Please enter valid address'
                                                                    }
                                                                />
                                                                <div className='autocomplete-dropdown-container'>
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map((suggestion, key) => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                            ? {
                                                                                backgroundColor: '#fafafa',
                                                                                cursor: 'pointer',
                                                                            }
                                                                            : {
                                                                                backgroundColor: '#ffffff',
                                                                                cursor: 'pointer',
                                                                            };
                                                                        return (
                                                                            <div
                                                                                key={key}
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        disabled
                                                        label='City:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opCity'
                                                        name='opCity'
                                                        value={formik.values.opCity}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        error={
                                                            formik.touched.opCity &&
                                                            Boolean(formik.errors.opCity)
                                                        }
                                                        helperText={
                                                            formik.touched.opCity && formik.errors.opCity
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        disabled
                                                        label='State:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opState'
                                                        name='opState'
                                                        value={formik.values.opState}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        error={
                                                            formik.touched.opState &&
                                                            Boolean(formik.errors.opState)
                                                        }
                                                        helperText={
                                                            formik.touched.opState && formik.errors.opState
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        disabled
                                                        label='County:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opCounty'
                                                        name='opCounty'
                                                        value={formik.values.opCounty}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        error={
                                                            formik.touched.opCounty &&
                                                            Boolean(formik.errors.opCounty)
                                                        }
                                                        helperText={
                                                            formik.touched.opCounty && formik.errors.opCounty
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        disabled
                                                        label='Postal Code:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opPostalCode'
                                                        name='opPostalCode'
                                                        value={formik.values.opPostalCode}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        error={
                                                            formik.touched.opPostalCode &&
                                                            Boolean(formik.errors.opPostalCode)
                                                        }
                                                        helperText={
                                                            formik.touched.opPostalCode &&
                                                            formik.errors.opPostalCode
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='Phone Number:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opPhoneNumber'
                                                        name='opPhoneNumber'
                                                        value={formik.values.opPhoneNumber}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        inputProps={{ maxLength: 10 }}
                                                        error={
                                                            formik.touched.opPhoneNumber &&
                                                            Boolean(formik.errors.opPhoneNumber)
                                                        }
                                                        helperText={
                                                            formik.touched.opPhoneNumber &&
                                                            formik.errors.opPhoneNumber
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <Field
                                                        as={TextField}
                                                        label='OP Fax:'
                                                        variant='filled'
                                                        size='small'
                                                        id='opFax'
                                                        name='opFax'
                                                        value={formik.values.opFax}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        inputProps={{ maxLength: 10 }}
                                                        error={
                                                            formik.touched.opFax &&
                                                            Boolean(formik.errors.opFax)
                                                        }
                                                        helperText={
                                                            formik.touched.opFax && formik.errors.opFax
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <div className='md:w-full'>
                                                    <TextField
                                                        label="Patient's PCP Name:"
                                                        variant='filled'
                                                        size='small'
                                                        id='pcpName'
                                                        name='pcpName'
                                                        value={formik.values.pcpName}
                                                        onChange={formik.handleChange}
                                                        InputLabelProps={{ required: true }}
                                                        error={
                                                            formik.touched.pcpName &&
                                                            Boolean(formik.errors.pcpName)
                                                        }
                                                        helperText={
                                                            formik.touched.pcpName && formik.errors.pcpName
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Medical Details */}
                                <Grid item>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Medical Details:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-2'>
                                            Please enter the medical details for the patient.
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={4}
                                        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <FormControl
                                                sx={{ minWidth: 200, width: '100%' }}
                                                variant='filled'
                                                size='small'
                                            >
                                                <InputLabel id='agency'>
                                                    Urgency
                                                    <span className='text-rose-400'>*</span>
                                                </InputLabel>
                                                <Select
                                                    labelId='Urgency'
                                                    id='Urgency'
                                                    name='Urgency'
                                                    value={formik.values.Urgency}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.Urgency &&
                                                        Boolean(formik.errors.Urgency)
                                                    }
                                                >
                                                    <MenuItem value='Standard'>Standard</MenuItem>
                                                    <MenuItem value='Expedited'>Expedited</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    <span className='text-red-500'>
                                                        {formik.touched.Urgency && formik.errors.Urgency}
                                                    </span>
                                                </FormHelperText>
                                                <FormHelperText>
                                                    {formik.values.Urgency === 'Expedited' && (
                                                        <span>
                                                            Expedited organization determination (urgent
                                                            requests), can only be requested by the member (or
                                                            their representative) or a Physician. CMS
                                                            Regulation: 50.1
                                                        </span>
                                                    )}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {formik.values.Urgency === 'Expedited' && (
                                        <Grid
                                            container
                                            rowSpacing={2}
                                            columnSpacing={{ xs: 1, sm: 2 }}
                                        >
                                            <Grid item xs={10}>
                                                <FormControl
                                                    sx={{ width: '100%', marginBottom: '20px' }}
                                                    variant='filled'
                                                    size='small'
                                                >
                                                    <TextField
                                                        id='urgency-reason'
                                                        name='UrgencyReason'
                                                        label='Urgency Reason'
                                                        multiline
                                                        rows={2}
                                                        InputLabelProps={{ required: true }}
                                                        variant='filled'
                                                        size='small'
                                                        value={formik.values.UrgencyReason}
                                                        onChange={formik.handleChange}
                                                        error={
                                                            formik.touched.UrgencyReason &&
                                                            Boolean(formik.errors.UrgencyReason)
                                                        }
                                                        helperText={
                                                            formik.touched.UrgencyReason &&
                                                            formik.errors.UrgencyReason
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                        sx={{ marginTop: '2px' }}
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Date of D/C:'
                                                    variant='filled'
                                                    size='small'
                                                    id='dateOfDischarge'
                                                    name='dateOfDischarge'
                                                    type='date'
                                                    value={formik.values.dateOfDischarge}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.dateOfDischarge &&
                                                        Boolean(formik.errors.dateOfDischarge)
                                                    }
                                                    helperText={
                                                        formik.touched.dateOfDischarge &&
                                                        formik.errors.dateOfDischarge
                                                    }
                                                />
                                                <FormHelperText>
                                                    *Date of D/C from facility and/or office visit
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Primary Diagnosis:'
                                                    variant='filled'
                                                    size='small'
                                                    id='Diagnosis'
                                                    name='Diagnosis'
                                                    value={formik.values.Diagnosis}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{ required: true }}
                                                    error={
                                                        formik.touched.Diagnosis &&
                                                        Boolean(formik.errors.Diagnosis)
                                                    }
                                                    helperText={
                                                        formik.touched.Diagnosis && formik.errors.Diagnosis
                                                    }
                                                />
                                                <FormHelperText>
                                                    *Add comma seperated values ex: Covid,Anxiety
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Primary ICD 10:'
                                                    variant='filled'
                                                    size='small'
                                                    id='DiagnosisCode'
                                                    name='DiagnosisCode'
                                                    value={formik.values.DiagnosisCode}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{ required: true }}
                                                    error={
                                                        formik.touched.DiagnosisCode &&
                                                        Boolean(formik.errors.DiagnosisCode)
                                                    }
                                                    helperText={
                                                        formik.touched.DiagnosisCode &&
                                                        formik.errors.DiagnosisCode
                                                    }
                                                />
                                                <FormHelperText>*Primary Diagnosis Code</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Secondary ICD 10:'
                                                    variant='filled'
                                                    size='small'
                                                    id='SecondaryDiagnosisCode'
                                                    name='SecondaryDiagnosisCode'
                                                    value={formik.values.SecondaryDiagnosisCode}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.SecondaryDiagnosisCode &&
                                                        Boolean(formik.errors.SecondaryDiagnosisCode)
                                                    }
                                                    helperText={
                                                        formik.touched.SecondaryDiagnosisCode &&
                                                        formik.errors.SecondaryDiagnosisCode
                                                    }
                                                />
                                                <FormHelperText>
                                                    *Secondary Diagnosis Code
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Agency Details Information */}
                                <Grid item container>
                                    <Box>
                                        <div className='font-semibold text-sm mb-2'>
                                            Agency Details:
                                        </div>
                                        <div className='text-gray-400 text-xs mb-2'>
                                            Please enter agency details if an agency is preferred by
                                            or for this member.
                                        </div>
                                    </Box>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 1, sm: 2 }}
                                    >
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Agency Name:'
                                                    variant='filled'
                                                    size='small'
                                                    id='agencyId'
                                                    name='agencyId'
                                                    value={formik.values.agencyId}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.agencyId &&
                                                        Boolean(formik.errors.agencyId)
                                                    }
                                                    helperText={
                                                        formik.touched.agencyId &&
                                                        formik.errors.agencyId
                                                    }
                                                />
                                                {/*<FormHelperText>*/}
                                                {/*    *Agency Name*/}
                                                {/*</FormHelperText>*/}
                                            </FormControl>
                                            <div>
                                                <span className='text-red-500'>
                                                    {formik.touched.agencyId && formik.errors.agencyId}
                                                </span>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Agency Email:'
                                                    variant='filled'
                                                    size='small'
                                                    id='ProviderEmail'
                                                    name='ProviderEmail'
                                                    type='email'
                                                    value={formik.values.ProviderEmail}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.ProviderEmail &&
                                                        Boolean(formik.errors.ProviderEmail)
                                                    }
                                                    helperText={
                                                        formik.touched.ProviderEmail &&
                                                        formik.errors.ProviderEmail
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Agency Contact Name:'
                                                    variant='filled'
                                                    size='small'
                                                    id='StaffingReferralAgencyName'
                                                    name='StaffingReferralAgencyName'
                                                    type='text'
                                                    value={formik.values.StaffingReferralAgencyName}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.StaffingReferralAgencyName &&
                                                        Boolean(formik.errors.StaffingReferralAgencyName)
                                                    }
                                                    helperText={
                                                        formik.touched.StaffingReferralAgencyName &&
                                                        formik.errors.StaffingReferralAgencyName
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Agency Fax:'
                                                    variant='filled'
                                                    size='small'
                                                    id='ProviderFax'
                                                    name='ProviderFax'
                                                    type='text'
                                                    value={formik.values.ProviderFax}
                                                    inputProps={{ maxLength: 10 }}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.ProviderFax &&
                                                        Boolean(formik.errors.ProviderFax)
                                                    }
                                                    helperText={
                                                        formik.touched.ProviderFax &&
                                                        formik.errors.ProviderFax
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl
                                                sx={{ minWidth: 270 }}
                                                variant='filled'
                                                size='small'
                                            >
                                                <InputLabel id='ApprovedAuthPreference'>
                                                    Send Approved Auth to:
                                                </InputLabel>
                                                <Select
                                                    labelId='ApprovedAuthPreference'
                                                    id='ApprovedAuthPreference'
                                                    name='ApprovedAuthPreference'
                                                    value={formik.values.ApprovedAuthPreference}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.ApprovedAuthPreference &&
                                                        Boolean(formik.errors.ApprovedAuthPreference)
                                                    }
                                                >
                                                    <MenuItem value='Email'>Email</MenuItem>
                                                    <MenuItem value='Fax'>Fax</MenuItem>
                                                    <MenuItem value='Both'>Both</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    <span className='text-red-500'>
                                                        {formik.touched.ApprovedAuthPreference &&
                                                            formik.errors.ApprovedAuthPreference}
                                                    </span>
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Clinical Grouping */}
                                <Grid item>
                                    <FormControl
                                        sx={{ minWidth: 270 }}
                                        variant='filled'
                                        size='small'
                                    >
                                        <InputLabel id='ClinicalGroup'>
                                            Clinical Grouping<span className='text-rose-400'>*</span>
                                        </InputLabel>
                                        <Select
                                            labelId='ClinicalGroup'
                                            id='ClinicalGroup'
                                            name='ClinicalGroup'
                                            value={formik.values.ClinicalGroup}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.ClinicalGroup &&
                                                Boolean(formik.errors.ClinicalGroup)
                                            }
                                        >
                                            {clinicalGroupingList.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            <span className='text-red-500'>
                                                {formik.touched.ClinicalGroup &&
                                                    formik.errors.ClinicalGroup}
                                            </span>
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        sx={{ minWidth: 270 }}
                                        variant='filled'
                                        size='small'
                                    >
                                        <InputLabel id='PriorityCareTypes'>
                                            Care Type Required<span className='text-rose-400'>*</span>
                                        </InputLabel>
                                        <Select
                                            labelId='PriorityCareTypes'
                                            id='PriorityCareTypes'
                                            name='PriorityCareTypes'
                                            value={formik.values.PriorityCareTypes}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.PriorityCareTypes &&
                                                Boolean(formik.errors.PriorityCareTypes)
                                            }
                                        >
                                            <MenuItem value='LovenoxInjections'>
                                                Lovenox Injections
                                            </MenuItem>
                                            <MenuItem value='FeedingTube'>Feeding Tube</MenuItem>
                                            <MenuItem value='LeftVentricularAssistDevice'>
                                                LVAD
                                            </MenuItem>
                                            <MenuItem value='Tracheostomy'>Tracheostomy</MenuItem>
                                            <MenuItem value='None'>None</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            <span className='text-red-500'>
                                                {formik.touched.PriorityCareTypes &&
                                                    formik.errors.PriorityCareTypes}
                                            </span>
                                        </FormHelperText>
                                        <FormHelperText>
                                            PICC line care* <br />
                                            Behavioral Health/Psychiatric* <br />
                                            Home Infusion Therapy* <br />
                                            tango is not contracted, theses care types must be
                                            coordinated through the Health Plan*
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                {/* Checkboxes */}
                            </Grid>
                            {/* Care Type */}
                            <div className='my-5 py-5 border-b-2 text-small'>
                                <Grid
                                    item
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                ></Grid>
                            </div>
                            <div className='my-5 py-5 border-b-2'>
                                <Grid
                                    item
                                    container
                                    direction='row'
                                    justifyContent='space-evenly'
                                    alignItems='center'
                                >
                                    <Box>
                                        Which skilled disciplines <br />
                                        are ordered for start of care? <br />
                                        CHOOSE ALL THAT APPLY.{' '}
                                        <span className='text-rose-400'>*</span>
                                    </Box>
                                    <div style={{ minWidth: '250px' }} id='Disciplines'>
                                        <MultiSelect
                                            options={disciplineOptions}
                                            value={selected}
                                            onChange={handleDisciplineSelect}
                                            labelledBy='Select'
                                            name='Disciplines'
                                            hasSelectAll={false}
                                        />

                                        <div>
                                            <span className='text-red-500'>
                                                {formik.touched.Disciplines &&
                                                    formik.errors.Disciplines}
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                            <div className='my-5 py-5 '>
                                <Grid
                                    item
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    <div>
                                        <span className='font-bold'>
                                            Please submit the required documents as <br /> following
                                            as attachments:
                                        </span>

                                        <div className='my-2'>
                                            <span className='text-red-600'>*</span> MD, DO, DPM,
                                            NP/APRN, or PA Home Healthcare order
                                        </div>
                                        <div>
                                            <span className='text-red-600'>*</span> Supporting
                                            Clinical Documenatation
                                        </div>
                                    </div>

                                    <Grid item>
                                        <div className='font-bold'>
                                            Please provide the following documents, if applicable:
                                        </div>
                                        <div className='my-2'>H&P</div>
                                        <div className='my-2'>Inpatient Discharge Summary</div>
                                        <div className='my-2'>
                                            Notes from Hospital or SNF (including any therapy notes)
                                        </div>
                                        <div className='my-2'>MD Office Notes</div>
                                        <div className='my-2'>
                                            Wound Care Notes with Measurements
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='my-5 py-5'>
                                <div className='font-bold'>
                                    Please upload your attachments here{' '}
                                    <span className='text-red-500'>*</span>
                                </div>
                                <div className='italic'>
                                    To select multiple files hold the CTRL key and select each
                                    individual file for uploading.
                                </div>
                                <div className='my-5'>
                                    <label
                                        htmlFor='attachments'
                                        style={{ cursor: 'pointer' }}
                                        className='btn primary'
                                    >
                                        Choose File
                                        <input
                                            type='file'
                                            id='attachments'
                                            name='BlobFile'
                                            accept='image/png, image/jpeg, .pdf, .doc, .docx'
                                            onChange={updateList}
                                            multiple
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                    <div className='text-red-500 mt-5'>
                                        {formik.touched.attachments && formik.errors.attachments}
                                    </div>
                                </div>
                                <div id='error_blobfile'></div>
                                <div className='file-list'>
                                    <ul>
                                        {fileList.map((file) => (
                                            <li key={file.name}>
                                                <div className='file-info'>
                                                    <span className='file-name'>{file.name}</span>
                                                    <i
                                                        className='fa fa-times close-icon'
                                                        onClick={() => removeFile(file)}
                                                    ></i>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='signature-block'>
                                <div
                                    style={{
                                        fontSize: '14px',
                                        lineHeight: '1.5',
                                        color: '#333333',
                                    }}
                                >
                                    <div style={{ marginBottom: '20px' }}>
                                        <span style={{ fontWeight: 'bold' }}>
                                            For Medicare Beneficiary:
                                        </span>{' '}
                                        the F2F encounter date must be within 90 days prior to or 30
                                        days after the date of home care admission and related to
                                        the reason for the home care referral.
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <span style={{ fontWeight: 'bold' }}>
                                            I certify that this patient is under my care and that I,
                                            or a NP/PA working with me, had a face to face encounter
                                            on:
                                        </span>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <Grid item xs={6} sm={4} md={3}>
                                                <FormControl>
                                                    <Field
                                                        as={TextField}
                                                        variant='filled'
                                                        size='small'
                                                        label='F2F Encounter Date:'
                                                        id='previousF2FDate'
                                                        name='previousF2FDate'
                                                        type='date'
                                                        value={formik.values.previousF2FDate}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={formik.handleChange}
                                                        error={
                                                            formik.touched.previousF2FDate &&
                                                            Boolean(formik.errors.previousF2FDate)
                                                        }
                                                        helperText={
                                                            formik.touched.previousF2FDate &&
                                                            formik.errors.previousF2FDate
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <FormControl>
                                                <Field
                                                    as={TextField}
                                                    label='Name of Provider:'
                                                    variant='filled'
                                                    className=''
                                                    size='small'
                                                    id='previousF2FPhysician'
                                                    name='previousF2FPhysician'
                                                    data-testid='previousF2FPhysician'
                                                    inputProps={{ maxLength: 50 }}
                                                    value={formik.values.previousF2FPhysician}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.previousF2FPhysician &&
                                                        Boolean(formik.errors.previousF2FPhysician)
                                                    }
                                                    helperText={
                                                        formik.touched.confirmingProvider &&
                                                        formik.errors.confirmingProvider
                                                    }
                                                />
                                                <FormHelperText>
                                                    *Name of Provider confirming Face to Face
                                                </FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value={certify}
                                                    onChange={() => setCertify(!certify)}
                                                />
                                            }
                                            label={
                                                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    For Medicare Advantage Members: I certify that this
                                                    member is considered homebound within the definition
                                                    of homebound.
                                                    <br />
                                                    For Medicaid Members: I certify that this member
                                                    requires home health care services.
                                                    <br />
                                                    <span
                                                        style={{ fontWeight: 'normal', fontSize: '12px' }}
                                                    >
                                                        Homebound means that leaving home takes considerable
                                                        and taxing effort. A person may leave home for
                                                        medical treatment or short, infrequent absences for
                                                        non-medical reason.
                                                    </span>
                                                </span>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='mt-8 mb-8 text-center'>
                                    Please sign here confirming all the information above is true:
                                    <SignatureCanvas
                                        ref={sigCanvas}
                                        id='secureSignature'
                                        penColor='black'
                                        onEnd={save}
                                        canvasProps={{
                                            width: !isSmallScreen ? 600 : 385,
                                            height: 200,
                                            className: 'default-signature',
                                            id: 'signature',
                                        }}
                                        data-testid='signature-canvas'
                                    />
                                    {signatureError && (
                                        <div className='text-red-500'>{signatureError}</div>
                                    )}
                                    <button
                                        type='button'
                                        className='btn secondary mt-5'
                                        onClick={clear}
                                    >
                                        Clear
                                    </button>
                                    {signatureSaved && (
                                        <div className='text-green-500 mt-5'>{signatureSaved}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='mx-auto mt-5 flex justify-center'>
                            <button
                                className='btn primary disabled:opacity-25'
                                type='submit'
                                style={{ marginRight: '10px' }}
                            >
                                Request Authorization{' '}
                            </button>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '15px',
                                fontWeight: 'bold',
                            }}
                        >
                            If you are unable to complete this form please fax your request to
                            877-612-7066, or call tango. at (888) 705-5274
                        </div>
                    </Form>
                )}
            </Formik>
            <Modal
                open={referralPostSuccessful}
                onClose={handleSuccessClose}
                aria-labelledby='success-modal'
                aria-describedby='referral-successful'
                data-testid='referral-successful'
            >
                <Box sx={style} autohideduration={3000}>
                    <CheckBoxIcon fontSize='large' color='success' />
                    <Typography id='success-modal' variant='h6' component='h2'>
                        A Referral was created successfully.
                    </Typography>
                    <Typography id='referral-success' sx={{ mt: 2 }}>
                        Referral ID : {successfulReferralId}
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={referralPostError}
                onClose={handleErrorClose}
                aria-labelledby='error-modal'
                aria-describedby='referral-error'
                data-testid='referral-error'
            >
                <Box sx={errorStyle} autohideduration={3000}>
                    <DoDisturbOnIcon fontSize='large' sx={{ color: red[500] }} />
                    <Typography id='error-modal' variant='h6' component='h2'>
                        There was an error creating your referral.
                    </Typography>
                    <Typography id='referral-error' sx={{ mt: 2 }}>
                        If you are unable to complete this form please fax your request to
                        877-612-7066, or call tango at (888) 705-5274.
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default PriorAuth;
