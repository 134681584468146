import { CONFIG } from './constants';
import {  get, post } from './utility';

//export async function getIsPatientEligible(patientInfo) {
//    let response = await post('/Insurances/Search', patientInfo);
//    return response;
//}

//export async function postAgreement(agreementData) {
//    const response = await post('/userAgreement', agreementData);
//    return response.data;
//}

//export async function getPayors() {
//    const { payors } = await get('/Payors');

//    payors.sort((a, b) =>
//        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
//    );

//    return payors;
//}

//export async function postReferral(referral) {
//    var uri = `/postReferral`;
//    try {
//        const response = await post(uri, referral, {
//            headers: {
//                'Content-Type': 'multipart/form-data',
//            },
//        });
//        return response;
//    } catch (error) {
//        console.log(error);
//        return error;
//    }
//}

//export async function getAgencyList() {
//    try {
//        const response = await get('/agencyList');
//        const formattedList = formattedAgencyList(response.results);

//        return formattedList;
//    } catch (e) {
//        return e;
//    }
//}

export async function ingestReferral(referral, submittedBy, signature, fileList) {
    const uri =`${CONFIG.REACT_APP_INJEST_URL}Referrals/Process`;
    //const formData = convertToArchiveRequest(referral);
    const formData = convertToFormRequest(referral, submittedBy, signature, fileList);
    try {
        const response = await post(uri, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const guidRegex =
            /^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}$/i;

        if (!guidRegex.test(response.data)) {
            throw new Error('Invalid GUID format');
        }
        return response.data;
    } catch (error) {
        return error;
    }
}

//async function postReferralWithAgreement(
//    referral,
//    submittedBy,
//    signature,
//    injestUid
//) {
//    try {
//        const postReferralResponse = await postReferral(referral);

//        if (postReferralResponse instanceof Error) {
//            return postReferralResponse.message;
//        }

//        try {
//            const agreementObj = {
//                FirstName: submittedBy.firstName,
//                LastName: submittedBy.lastName,
//                Email: submittedBy.email,
//                SignatureImage: signature,
//                InjestUid: injestUid,
//                PreviousF2FDate:
//                    referral.get('PreviousF2FDate') === ''
//                        ? null
//                        : referral.get('PreviousF2FDate'),
//                PreviousF2FPhysician:
//                    referral.get('PreviousF2FPhysician') === ''
//                        ? null
//                        : referral.get('PreviousF2FPhysician'),
//            };
//            await postAgreement(agreementObj);
//        } catch (e) {
//            return e;
//        }
//        return postReferralResponse.data.referralId;
//    } catch (e) {
//        return e;
//    }
//}

function base64ToFile(base64Image, filename) {
    try {
        const base64ContentArray = base64Image.split(","); // Split into type and actual base64 content
        const mimeType = base64ContentArray[0].match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]; // Extract mimeType
        const byteCharacters = atob(base64ContentArray[1]); // Decode base64
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new File([byteArray], filename, { type: mimeType }); // Create File object
        return file;
    } catch (e) {
        return;
    }    
}

function convertToFormRequest(referral, submittedBy, signature, fileList) {
    const formData = new FormData();    
    formData.append('content', JSON.stringify(referral, null, 2));
    formData.append('source', 'ReferralRequest');

    if (signature) {
        const file = base64ToFile(signature, "signature.png"); 
        formData.append('files', file, file.name);
    }

    if (Array.isArray(fileList)) {
        fileList.forEach((file) => {
            formData.append('files', file, file.name);
        });
    }
    return formData;
}

//function convertToArchiveRequest(referral) {
//    const obj = {};
//    referral.forEach((value, key) => {
//        obj[key] = value;
//    });

//    let jsonString = JSON.stringify(obj, null, 2);

//    const formData = new FormData();
//    formData.append('Submitter', referral.get('SubmittedBy'));
//    formData.append('Payload', jsonString);
//    formData.append('SourceName', 'ReferralRequest.com');
//    for (const file of referral.getAll('BlobFile')) {
//        formData.append('Files', file);
//    }
//    return formData;
//}

export const getConfigs = async () => {
    const url = `/api/getConfig`;
    return await get(url);
}